import { useBi as useBILogger } from '@wix/yoshi-flow-editor';
import {
  actionButtonClicked,
  blockedMembersListOpen,
  memberJoinedCommunityAttempt,
  memberJoinedCommunityJoinedAfterPopUpUou,
  memberLeftCommunityAttempt,
  memberLeftCommunityLeftAfterPopup,
  myAccountPrivacySettingsChanged,
  profileWidgetEdited,
} from '@wix/bi-logger-members-app-uou/v2';
import {
  actionButtonClickedParams,
  memberJoinedCommunityAttemptParams,
  profileWidgetEditedParams,
} from '@wix/bi-logger-members-app-uou/v2/types';
import {
  siteMembersMemberClickedOnChangeEmail,
  siteMembersMemberClickedOnChangePassword,
  siteMembersChangeEmailModalLoad,
  siteMembersChangeEmailCtaClicks,
  siteMembersChangeEmailClickOnChange,
  siteMembersChangePasswordModalLoad,
  siteMembersChangePasswordCtaClicks,
  siteMembersChangePasswordClickOnChange,
} from '@wix/bi-logger-identity-data/v2';

import { getBiProfileSaveData } from './getBiProfileSaveData';
import { useMember } from '../../contexts/widget';
import { ProfilePrivacyStatus } from '../../types/member';
import {
  BiActionType,
  Field,
  FieldPrivacy,
  MY_ACCOUNT_REFERRAL,
} from '../../types';

enum BiPrivacy {
  Only_Me = 'only_me',
  All_Members = 'all_members',
}

interface ProfileEditedProps {
  changedFields: Field[];
  changedPrivacyFields: Field[];
  urlChanged: boolean;
}

const saveOrigin = 'my_account';

const getBiPrivacy = (newPrivacy: FieldPrivacy) =>
  newPrivacy === FieldPrivacy.PRIVATE
    ? BiPrivacy.Only_Me
    : BiPrivacy.All_Members;

const isProfilePublic = (status: ProfilePrivacyStatus) =>
  status === ProfilePrivacyStatus.PUBLIC;

const getChangedPrivacyFieldsBIString = (
  changedPrivacyFields: Field[],
  fieldPrivacy: FieldPrivacy[],
) =>
  changedPrivacyFields
    .filter((field) => fieldPrivacy.includes(field.privacy))
    .map((field) => field.label)
    .join(',');

export const useBi = () => {
  const biLogger = useBILogger();
  const { privacyStatus, role } = useMember();

  const withCommonProps = (
    props?:
      | profileWidgetEditedParams
      | actionButtonClickedParams
      | memberJoinedCommunityAttemptParams,
  ) => {
    const common = {
      ...(role && { role }),
      is_social: isProfilePublic(privacyStatus),
    };
    return { ...common, ...(props && { ...props }) };
  };

  const profileEdited = ({
    changedPrivacyFields = [],
    changedFields = [],
    urlChanged = false,
  }: Partial<ProfileEditedProps>) => {
    const biProfileSaveData = changedFields
      ? getBiProfileSaveData(changedFields)
      : null;
    const privacyOnlyMe = getChangedPrivacyFieldsBIString(
      changedPrivacyFields,
      [FieldPrivacy.PRIVATE],
    );
    const privacyAllMembers = getChangedPrivacyFieldsBIString(
      changedPrivacyFields,
      [FieldPrivacy.PUBLIC],
    );
    const privacyChanged = changedPrivacyFields.length > 0;

    biLogger.report(
      profileWidgetEdited(
        withCommonProps({
          saveOrigin,
          privacyChanged,
          urlChanged,
          ...(biProfileSaveData && { ...biProfileSaveData }),
          ...(privacyOnlyMe && { privacyOnlyMe }),
          ...(privacyAllMembers && { privacyAllMembers }),
        }),
      ),
    );
  };

  const fieldPrivacyChanged = (
    fieldName: string,
    newFieldPrivacy: FieldPrivacy,
    fieldType: string,
  ) => {
    const value = getBiPrivacy(newFieldPrivacy);
    biLogger.report(
      myAccountPrivacySettingsChanged({ fieldName, value, fieldType }),
    );
  };

  const ctaButtonClicked = (action_type: BiActionType) => {
    biLogger.report(
      actionButtonClicked(
        withCommonProps({
          action_type,
        }),
      ),
    );
  };

  const ctaButtonClickedWithProps = (action_type: BiActionType, props: any) => {
    biLogger.report(
      actionButtonClicked(
        withCommonProps({
          action_type,
          ...props,
        }),
      ),
    );
  };

  const leaveCommunityAttempt = () =>
    biLogger.report(
      memberLeftCommunityAttempt(
        withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
      ),
    );

  const joinCommunityAttempt = () =>
    biLogger.report(
      memberJoinedCommunityAttempt(
        withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
      ),
    );

  const leftCommunity = () =>
    biLogger.report(
      memberLeftCommunityLeftAfterPopup(
        withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
      ),
    );

  const joinedCommunity = () =>
    biLogger.report(
      memberJoinedCommunityJoinedAfterPopUpUou(
        withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
      ),
    );

  const logBlockedMembersListOpen = (membersCount: number) => {
    biLogger.report(
      blockedMembersListOpen({
        membersCount,
        referralinfo: MY_ACCOUNT_REFERRAL,
      }),
    );
  };

  const memberClickedOnChangeEmail = () => {
    biLogger.report(siteMembersMemberClickedOnChangeEmail({}));
  };

  const memberClickedOnChangePassword = () => {
    biLogger.report(siteMembersMemberClickedOnChangePassword({}));
  };

  const changeEmailModalLoaded = () => {
    biLogger.report(siteMembersChangeEmailModalLoad({}));
  };

  const memberClickedOnExitEmailChange = () => {
    biLogger.report(
      siteMembersChangeEmailCtaClicks({
        button_click: 'exit',
      }),
    );
  };

  const memberClickedOnCancelEmailChange = () => {
    biLogger.report(
      siteMembersChangeEmailCtaClicks({
        button_click: 'cancel',
      }),
    );
  };

  const memberClickedOnResetOrCreatePasswordEmailDialog = () => {
    biLogger.report(
      siteMembersChangeEmailCtaClicks({
        button_click: 'reset_or_create_password',
      }),
    );
  };

  const memberSubmittedChangeEmail = (
    success: boolean,
    failureReason?: string,
  ) => {
    biLogger.report(
      siteMembersChangeEmailClickOnChange({
        is_success: success,
        failure_reason: failureReason,
      }),
    );
  };

  const changePasswordModalLoaded = () => {
    biLogger.report(siteMembersChangePasswordModalLoad({}));
  };

  const memberClickedOnExitPasswordChange = () => {
    biLogger.report(
      siteMembersChangePasswordCtaClicks({
        button_click: 'exit',
      }),
    );
  };

  const memberClickedOnCancelPasswordChange = () => {
    biLogger.report(
      siteMembersChangePasswordCtaClicks({
        button_click: 'cancel',
      }),
    );
  };

  const memberClickedOnResetOrCreatePasswordPasswordDialog = () => {
    biLogger.report(
      siteMembersChangePasswordCtaClicks({
        button_click: 'reset_or_create_password',
      }),
    );
  };

  const memberSubmittedChangePassword = (
    success: boolean,
    failureReason?: string,
  ) => {
    biLogger.report(
      siteMembersChangePasswordClickOnChange({
        is_success: success,
        failure_reason: failureReason,
      }),
    );
  };

  return {
    profileEdited,
    fieldPrivacyChanged,
    ctaButtonClicked,
    ctaButtonClickedWithProps,
    leaveCommunityAttempt,
    joinCommunityAttempt,
    leftCommunity,
    joinedCommunity,
    blockedMembersListOpen: logBlockedMembersListOpen,
    memberClickedOnChangeEmail,
    memberClickedOnChangePassword,
    changeEmailModalLoaded,
    memberClickedOnExitEmailChange,
    memberClickedOnCancelEmailChange,
    memberClickedOnResetOrCreatePasswordEmailDialog,
    memberSubmittedChangeEmail,
    changePasswordModalLoaded,
    memberClickedOnExitPasswordChange,
    memberClickedOnCancelPasswordChange,
    memberClickedOnResetOrCreatePasswordPasswordDialog,
    memberSubmittedChangePassword,
  };
};
