import { Address, Contact } from '@wix/ambassador-members-v1-member/types';
import {
  WIX_BLOG,
  WIX_EVENTS,
  WIX_FORUM,
  WIX_GROUPS,
} from '@wix/app-definition-ids';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { Field, FieldOrigin, FieldType } from '../types';

type ReservedFieldPreset = {
  key: string;
  contactPropName: keyof Contact;
  type: FieldType;
  origin: FieldOrigin;
};

const customFieldsNamespace = 'customfields';
const systemNamespace = 'system';
const contactNamespace = 'contact';

export const EMAILS_FIELD_KEY =
  `${customFieldsNamespace}.${systemNamespace}.email` as keyof Contact;
export const PHONES_FIELD_KEY =
  `${customFieldsNamespace}.${systemNamespace}.phone` as keyof Contact;
export const ADDRESS_FIELD_KEY =
  `${customFieldsNamespace}.${systemNamespace}.address` as keyof Contact;
export const BIRTHDAY_FIELD_KEY =
  `${customFieldsNamespace}.${contactNamespace}.birthdate` as keyof Contact;
export const FIRST_NAME_FIELD_KEY =
  `${customFieldsNamespace}.${systemNamespace}.firstname` as keyof Contact;
export const LAST_NAME_FIELD_KEY =
  `${customFieldsNamespace}.${systemNamespace}.lastname` as keyof Contact;
export const POSITION_FIELD_KEY =
  `${customFieldsNamespace}.${contactNamespace}.position` as keyof Contact;
export const COMPANY_FIELD_KEY =
  `${customFieldsNamespace}.${contactNamespace}.company` as keyof Contact;

export const reservedFieldsPresets: ReservedFieldPreset[] = [
  {
    key: FIRST_NAME_FIELD_KEY,
    contactPropName: 'firstName',
    type: FieldType.TEXT,
    origin: FieldOrigin.SYSTEM,
  },
  {
    key: LAST_NAME_FIELD_KEY,
    contactPropName: 'lastName',
    type: FieldType.TEXT,
    origin: FieldOrigin.SYSTEM,
  },
  {
    key: EMAILS_FIELD_KEY,
    contactPropName: 'emails',
    type: FieldType.TEXT,
    origin: FieldOrigin.SYSTEM,
  },
  {
    key: PHONES_FIELD_KEY,
    contactPropName: 'phones',
    type: FieldType.TEXT,
    origin: FieldOrigin.SYSTEM,
  },
  {
    key: ADDRESS_FIELD_KEY,
    contactPropName: 'addresses',
    type: FieldType.TEXT,
    origin: FieldOrigin.SYSTEM,
  },
  {
    key: BIRTHDAY_FIELD_KEY,
    contactPropName: 'birthdate',
    type: FieldType.DATE,
    origin: FieldOrigin.CONTACT,
  },
  {
    key: POSITION_FIELD_KEY,
    contactPropName: 'jobTitle',
    type: FieldType.TEXT,
    origin: FieldOrigin.CONTACT,
  },
  {
    key: COMPANY_FIELD_KEY,
    contactPropName: 'company',
    type: FieldType.TEXT,
    origin: FieldOrigin.CONTACT,
  },
];

type AddressSubfieldPreset = {
  key: Field['key'];
  propName: keyof Address;
  type: Field['type'];
};

export const CITY_FIELD_KEY = `${customFieldsNamespace}.${systemNamespace}.city`;
export const COUNTRY_FIELD_KEY = `${customFieldsNamespace}.${systemNamespace}.country`;
export const SUBDIVISION_FIELD_KEY = `${customFieldsNamespace}.${systemNamespace}.subdivision`;
export const POSTAL_CODE_FIELD_KEY = `${customFieldsNamespace}.${systemNamespace}.postalcode`;
export const ADDRESS_LINE_FIELD_KEY = `${customFieldsNamespace}.${systemNamespace}.addressline`;

export const addressSubfieldsPresets: AddressSubfieldPreset[] = [
  {
    key: CITY_FIELD_KEY,
    propName: 'city',
    type: FieldType.TEXT,
  },
  {
    key: COUNTRY_FIELD_KEY,
    propName: 'country',
    type: FieldType.DROPDOWN,
  },
  {
    key: SUBDIVISION_FIELD_KEY,
    propName: 'subdivision',
    type: FieldType.DROPDOWN,
  },
  {
    key: POSTAL_CODE_FIELD_KEY,
    propName: 'postalCode',
    type: FieldType.TEXT,
  },
  {
    key: ADDRESS_LINE_FIELD_KEY,
    propName: 'addressLine',
    type: FieldType.TEXT,
  },
];

export const INVALID_CUSTOM_FIELD_RULE_NAME = 'INVALID_CUSTOM_FIELD_URL';

export const SOCIAL_APPS = [
  WIX_FORUM,
  WIX_GROUPS,
  WIX_EVENTS,
  WIX_BLOG,
  MA_APP_IDS.FILE_SHARE.appDefinitionId,
  MA_APP_IDS.ABOUT.appDefinitionId,
  MA_APP_IDS.SHARED_GALLERY.appDefinitionId,
  MA_APP_IDS.FOLLOWERS.appDefinitionId,
];

export const SSO_APPDEF_ID = '4f199cbe-22ee-40c8-9129-11f2b4178a38';

export const SOCIAL_CONNECTION_APP_DEF_IDS: Record<string, string> = {
  'ce1be235-3691-4532-906c-5a7d2e3acdd1': 'Apple',
  '3ecad13f-52c3-483d-911f-31dbcf2a6d23': 'Facebook',
  '0e6a50f5-b523-4e29-990d-f37fa2ffdd69': 'Google',
};
