import React, { FC, ReactNode } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';
import { Role } from '@wix/ambassador-wix-sm-members-proxy/types';

import { useMember, useSiteContext } from '../../../../../contexts/widget';
import { classes } from './LoginInfo.st.css';

export const ConditionalWarningOrCta: FC<{
  children: ReactNode;
  fieldName: 'email' | 'password';
}> = ({ children, fieldName }) => {
  const { t } = useTranslation();
  const { role } = useMember();
  const { isSSOMandatory } = useSiteContext();

  let warning = '';

  switch (role) {
    case Role.OWNER:
      warning = t('app.widget.sections.login-info.owner.warning');
      break;
    case Role.CONTRIBUTOR:
      warning = t('app.widget.sections.login-info.contributor.warning');
      break;
    default:
      if (isSSOMandatory) {
        warning =
          fieldName === 'email'
            ? t('app.widget.sections.login-info.sso.email.warning')
            : t('app.widget.sections.login-info.sso.password.warning');
      }
  }

  if (warning) {
    return (
      <Text tagName="div" className={classes.warning}>
        {warning}
      </Text>
    );
  }

  return children;
};
