import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Formik, Field } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ChangeEmailValues, ModalType } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { LoginInfoTextField } from '../../common/LoginInfoTextField';
import { initialValues } from './constants';
import { useChangeEmailValidation } from './useChangeEmailValidation';
import { LoginInfoDialog } from '../../common/LoginInfoDialog/LoginInfoDialog';
import { LoginInfoTextButton } from '../../common/LoginInfoTextButton';
import {
  useLoginInfoActions,
  useUiActions,
  useUiState,
} from '../../../../../contexts/widget';
import { stageToButtonState } from '../../common/LoginInfoDialog';
import { useBi } from '../../../../../hooks';
import { NotificationSection } from './NotificationSection';

export const ChangeLoginEmailModal: FC<ModalType> = ({ onClose }) => {
  const { t } = useTranslation();
  const validate = useChangeEmailValidation();
  const { changeEmailState } = useUiState();
  const { resetLoginInfoState } = useUiActions();
  const { recoverPassword, updateLoginEmail } = useLoginInfoActions();
  const biLogger = useBi();

  const initialErrors = useMemo(
    () =>
      changeEmailState.stage === 'fail' ? changeEmailState.errors : undefined,
    [changeEmailState],
  );

  const changeEmail = useCallback(
    ({ newEmail, password }: ChangeEmailValues) => {
      updateLoginEmail({ newEmail, password });
    },
    [updateLoginEmail],
  );

  const forgotPassword = useCallback(() => {
    biLogger.memberClickedOnResetOrCreatePasswordEmailDialog();
    recoverPassword();
  }, [recoverPassword, biLogger]);

  const closeDialog = useCallback(() => {
    biLogger.memberClickedOnExitEmailChange();
    onClose();
    resetLoginInfoState();
  }, [onClose, resetLoginInfoState, biLogger]);

  const cancelDialog = useCallback(() => {
    biLogger.memberClickedOnCancelEmailChange();
    onClose();
    resetLoginInfoState();
  }, [onClose, resetLoginInfoState, biLogger]);

  useEffect(() => {
    switch (changeEmailState.stage) {
      case 'ok':
        closeDialog();
        biLogger.memberSubmittedChangeEmail(true);
        break;
      case 'fail':
        if (!changeEmailState.errors) {
          const newEmail = document.forms
            .namedItem('changeEmail')
            ?.elements.namedItem('newEmail');
          if (newEmail instanceof HTMLInputElement) {
            newEmail.focus();
          }
        }
        biLogger.memberSubmittedChangeEmail(
          false,
          JSON.stringify(changeEmailState.errors),
        );
        break;
    }
  }, [changeEmailState.stage]);

  useEffect(() => {
    biLogger.changeEmailModalLoaded();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={changeEmail}
      validate={validate}
    >
      {({ submitForm }) => (
        <LoginInfoDialog
          name="changeEmail"
          state={stageToButtonState(changeEmailState.stage)}
          isOpen
          data-hook={DataHook.ChangeEmailModal}
          title={t('app.widget.modals.change-email.title')}
          subtitle={t('app.widget.modals.change-email.subtitle')}
          onClose={closeDialog}
          primaryText={t('app.widget.modals.change-email.change-button')}
          primaryOnClick={submitForm}
          secondaryText={t('app.widget.modals.change-email.cancel-button')}
          secondaryOnClick={cancelDialog}
        >
          <NotificationSection />

          <Field
            as={LoginInfoTextField}
            autoFocus
            name="newEmail"
            type="email"
            label={t('app.widget.modals.change-email.new-email.label')}
            data-hook={DataHook.ChangeEmailNewInput}
          />

          <Field
            as={LoginInfoTextField}
            name="confirmEmail"
            type="email"
            label={t('app.widget.modals.change-email.confirm-email.label')}
            data-hook={DataHook.ChangeEmailConfirmInput}
          />

          <Field
            as={LoginInfoTextField}
            type="password"
            name="password"
            label={t('app.widget.modals.change-email.password.label')}
            data-hook={DataHook.ChangeEmailPasswordInput}
            bottom={
              <div>
                <LoginInfoTextButton
                  onClick={forgotPassword}
                  data-hook={DataHook.ChangeEmailForgotPassword}
                >
                  {t('app.widget.modals.change-email.password.action')}
                </LoginInfoTextButton>
              </div>
            }
          />
        </LoginInfoDialog>
      )}
    </Formik>
  );
};

