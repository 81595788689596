import React, { FC } from 'react';
import {
  SectionNotification,
  SectionNotificationType,
} from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Alert } from '@wix/wix-ui-icons-common/on-stage';

import { useSiteContext } from '../../../../../contexts/widget';

export const NotificationSection: FC = () => {
  const { t } = useTranslation();
  const { enabledSocialConnections } = useSiteContext();

  const hasSingleSocialConnection = enabledSocialConnections.length === 1;

  const alertText = hasSingleSocialConnection
    ? t('app.widget.modals.change-email.warning-single-social', {
        social: enabledSocialConnections[0],
      })
    : t('app.widget.modals.change-email.warning');

  if (enabledSocialConnections.length > 0) {
    return (
      <SectionNotification type={SectionNotificationType.alert}>
        <SectionNotification.Icon icon={<Alert color="#FFB600" size={24} />} />
        <SectionNotification.Text>{alertText}</SectionNotification.Text>
      </SectionNotification>
    );
  } else {
    return null;
  }
};
