import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Text, TextPriority } from 'wix-ui-tpa/cssVars';
import { Formik, Field } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ChangePasswordValues, ModalType } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { LoginInfoTextField } from '../../common/LoginInfoTextField';
import { initialValues } from './constants';
import { useChangePasswordValidation } from './useChangePasswordValidation';
import { LoginInfoDialog } from '../../common/LoginInfoDialog/LoginInfoDialog';
import { classes } from './ChangeLoginPasswordModal.st.css';
import { LoginInfoTextButton } from '../../common/LoginInfoTextButton';
import {
  useLoginInfoActions,
  useUiActions,
  useUiState,
} from '../../../../../contexts/widget';
import { stageToButtonState } from '../../common/LoginInfoDialog';
import { useBi } from '../../../../../hooks';

export const ChangeLoginPasswordModal: FC<ModalType> = ({ onClose }) => {
  const { t } = useTranslation();
  const validate = useChangePasswordValidation();
  const { resetLoginInfoState } = useUiActions();
  const { changePasswordState } = useUiState();
  const { recoverPassword, updateLoginPassword } = useLoginInfoActions();
  const biLogger = useBi();

  const initialErrors = useMemo(
    () =>
      changePasswordState.stage === 'fail'
        ? changePasswordState.errors
        : undefined,
    [changePasswordState],
  );

  const changePassword = useCallback(
    ({ currentPassword, newPassword }: ChangePasswordValues) => {
      updateLoginPassword({ currentPassword, newPassword });
    },
    [updateLoginPassword],
  );

  const forgotPassword = useCallback(() => {
    biLogger.memberClickedOnResetOrCreatePasswordPasswordDialog();
    recoverPassword();
  }, [recoverPassword, biLogger]);

  const closeDialog = useCallback(() => {
    biLogger.memberClickedOnExitPasswordChange();
    onClose();
    resetLoginInfoState();
  }, [onClose, resetLoginInfoState, biLogger]);

  const cancelDialog = useCallback(() => {
    biLogger.memberClickedOnCancelPasswordChange();
    onClose();
    resetLoginInfoState();
  }, [onClose, resetLoginInfoState, biLogger]);

  useEffect(() => {
    switch (changePasswordState.stage) {
      case 'ok':
        closeDialog();
        biLogger.memberSubmittedChangePassword(true);
        break;
      case 'fail':
        if (!changePasswordState.errors) {
          const currentPassword = document.forms
            .namedItem('changePassword')
            ?.elements.namedItem('currentPassword');
          if (currentPassword instanceof HTMLInputElement) {
            currentPassword.focus();
          }
        }
        biLogger.memberSubmittedChangePassword(
          false,
          JSON.stringify(changePasswordState.errors),
        );
        break;
    }
  }, [changePasswordState.stage]);

  useEffect(() => {
    biLogger.changePasswordModalLoaded();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={changePassword}
      validate={validate}
    >
      {({ submitForm }) => {
        return (
          <LoginInfoDialog
            name="changePassword"
            state={stageToButtonState(changePasswordState.stage)}
            data-hook={DataHook.ChangePasswordModal}
            isOpen
            withoutDivider
            title={t('app.widget.modals.change-password.title')}
            subtitle={t('app.widget.modals.change-password.subtitle')}
            onClose={closeDialog}
            primaryOnClick={submitForm}
            primaryText={t('app.widget.modals.change-password.change-button')}
            secondaryText={t('app.widget.modals.change-password.cancel-button')}
            secondaryOnClick={cancelDialog}
          >
            <Field
              as={LoginInfoTextField}
              autoFocus
              type="password"
              data-hook={DataHook.ChangePasswordCurrentInput}
              label={t('app.widget.modals.change-password.password.label')}
              name="currentPassword"
              bottom={
                <div>
                  <LoginInfoTextButton
                    onClick={forgotPassword}
                    data-hook={DataHook.ChangePasswordForgotPassword}
                  >
                    {t('app.widget.modals.change-password.password.action')}
                  </LoginInfoTextButton>
                </div>
              }
            />

            <Field
              as={LoginInfoTextField}
              type="password"
              label={t('app.widget.modals.change-password.new-password.label')}
              data-hook={DataHook.ChangePasswordNewInput}
              name="newPassword"
              bottom={
                <Text
                  tagName="div"
                  priority={TextPriority.secondary}
                  className={classes.helpText}
                >
                  {t('app.widget.modals.change-password.new-password.help')}
                </Text>
              }
            />

            <Field
              type="password"
              as={LoginInfoTextField}
              label={t(
                'app.widget.modals.change-password.confirm-password.label',
              )}
              data-hook={DataHook.ChangePasswordConfirmInput}
              name="confirmPassword"
            />
          </LoginInfoDialog>
        );
      }}
    </Formik>
  );
};
